import PropTypes from "prop-types";
import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import styles from './blockWithChat.module.scss';

import cap from "@/assets/icons/cap.svg";
import hourglass from "@/assets/icons/hourglass.svg";
import thinX from "@/assets/icons/thinXShape.svg";
import calendar from "@/assets/insights/calendar.svg";
import tape from "@/assets/insights/measuringTape.svg";
import Card, { CardLinkPosition, CardType } from "@/components/Card";
import { HomeAge, HomeSize, PropertyAge } from "@/components/InsightCards";
import { Col, Flex, Row, Button, Img } from "@/components/Layout";
import { useDesktop, useDesktopMini, useMobileMini } from "@/hooks/useAdaptives";
import BigDisplayBlock from "@/views/PropertyInsights/issueComponents/bigDisplayBlock/bigDisplayBlock";
import { setPathColor } from "@/views/PropertyInsights/Main";

export const BlockWithChat = ({
    chat,
    setChat,
    message,
    handleMessage,
    handleKeyDown,
    chatContainerRef,
    chatMessages,
    isWaitingForBotResponse,
    loading,
    handleMessageSend,
    chatDisplay,
    setBigDisplay,
    handleCard,
    report,
    addressDetails
}) => {

    const isMobileMini = useMobileMini();
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();

    return (
        <Row className={`justify-content-center flex-wrap flex-lg-nowrap gap-2`}>
            <Col className={`${styles.big_display_block_wrapper} me-0 me-lg-2 align-self-stretch`}>
                <BigDisplayBlock className={'flex-grow-1'}>
                    <BigDisplayBlock.Header
                        className={`${styles.bigHeader} ${chat ? styles.bigHeader_sm : styles.bigHeader_lg}`}
                        chat={chat}>
                        <div className={'d-flex justify-content-between w-100'}>
                            <div className={'d-flex align-items-center gap-2'}>
                                <Img src={chatDisplay.icon} />
                                <h2>{chatDisplay.name}</h2>
                            </div>
                            <div className={'d-flex align-items-center gap-2'}>
                                <Img src={chatDisplay.image} style={{ width: '3.75rem', height: '3.75rem' }} />
                                <Button className={'flex-shrink-0'} onClick={() => { setBigDisplay(false) }}>Close Card</Button>
                            </div>
                        </div>
                    </BigDisplayBlock.Header>
                    <BigDisplayBlock.Body chat={chat}
                        className={`${chat ? `${styles.midHeader} ${styles.midHeader_on}` : ''}`}
                        setChat={setChat}
                        message={message}
                        handleMessage={handleMessage}
                        handleKeyDown={handleKeyDown}
                        isMobileMini={isMobileMini}
                        chatContainerRef={chatContainerRef}
                        chatMessages={chatMessages}
                        isWaitingForBotResponse={isWaitingForBotResponse}
                        loading={loading}
                        handleMessageSend={handleMessageSend}
                        withoutBodyAnimation={true}>
                        <h2>Description</h2>
                        <p>{chatDisplay.description}</p>
                    </BigDisplayBlock.Body>
                </BigDisplayBlock>
            </Col>

            {isDesktop || isDesktopMini ? <Col className={`${styles.scroll}`}>
                <Col className={'w-100 gap-3'}>
                    <HomeSize report={report} handleCard={handleCard} />
                    {/* <HomeAge report={report} handleCard={handleCard} /> */}
                    <PropertyAge report={report} handleCard={handleCard} />
                    <Card
                        type={CardType.smallBox}
                        linkPosition={CardLinkPosition.topRight}
                        onClick={() => {
                            handleCard('school')
                        }}
                    >
                        <Card.Header icon={cap}>{addressDetails?.city} Schools</Card.Header>
                        <Card.Body>
                            <Flex>
                                <div>
                                    {report?.details?.schools?.map?.(school => (
                                        <Row className={'flex-nowrap justify-content-start gap-3 my-2 mx-0'} key={school.link}>
                                            <div className={'flex-shrink-0'} style={{ maxWidth: '3.75rem' }}>
                                                <CircularProgressbar
                                                    value={school.rating}
                                                    maxValue={10}
                                                    text={`${school.rating}/10`}
                                                    styles={buildStyles(setPathColor(school.rating))}
                                                />
                                            </div>
                                            <div className={'text-start'}>{school.name}</div>
                                        </Row>
                                    )) || null}
                                </div>
                            </Flex>
                        </Card.Body>
                    </Card>
                </Col>
            </Col> : null
            }
        </Row>
    )
}

BlockWithChat.propTypes = {
    chat: PropTypes.bool,
    setChat: PropTypes.func,
    message: PropTypes.string,
    handleMessage: PropTypes.func,
    handleKeyDown: PropTypes.func,
    chatContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    chatMessages: PropTypes.array,
    isWaitingForBotResponse: PropTypes.bool,
    loading: PropTypes.string,
    handleMessageSend: PropTypes.func,
    chatDisplay: PropTypes.object,
    setBigDisplay: PropTypes.func,
    handleCard: PropTypes.func,
    report: PropTypes.object,
    addressDetails: PropTypes.string,
};