import PropTypes from 'prop-types';
import { useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import styles from './index.module.scss';

import hourglass from "@/assets/icons/hourglass.svg";
import thinX from "@/assets/icons/thinXShape.svg";
import calendar from "@/assets/insights/calendar.svg";
import tape from "@/assets/insights/measuringTape.svg";
import oldHouse from "@/assets/insights/oldHouse.svg";
import risk from "@/assets/insights/riskHouse.svg";
import house from "@/assets/insights/yellowHouse.svg";
import Card, { CardLinkPosition, CardType } from '@/components/Card';
import { Button, Col, Img, Row } from '@/components/Layout';
import { useDesktop, useDesktopMini, useLaptop } from '@/hooks/useAdaptives';

export const HomeSize = ({ report, handleCard }) => {
    return (
        <Card className={`${styles.small_card} w-100 align-items-start text-start`}
            type={CardType.smallBox}
            linkPosition={CardLinkPosition.topRight}
            onClick={() => {
                handleCard('size')
            }}
        >
            <Card.Header icon={thinX}>Home Size</Card.Header>
            <Card.Body>
                <div className={styles.small_card_body}>
                    <h1>{report?.details?.livingArea}</h1>
                    <p>{report?.details?.livingAreaUnits}</p>
                    <Img src={tape} />
                </div>
            </Card.Body>
        </Card>
    )
}

HomeSize.propTypes = {
    report: PropTypes.object.isRequired,
    handleCard: PropTypes.func.isRequired
}

export const HomeAge = ({ report, handleCard }) => {
    return (
        <Card className={`${styles.small_card} w-100 align-items-start text-start`}
            type={CardType.smallBox}
            linkPosition={CardLinkPosition.topRight}
            onClick={() => {
                handleCard('year')
            }}
        >
            <Card.Header icon={hourglass}>Property Age</Card.Header>
            <Card.Body>
                <div className={styles.small_card_body}>
                    <h1>{report?.details?.yearBuilt}</h1>
                    <p></p>
                    <Img src={calendar} />
                </div>
            </Card.Body>
        </Card>
    )
}

HomeAge.propTypes = {
    report: PropTypes.object.isRequired,
    handleCard: PropTypes.func.isRequired
}

export const PropertyAge = ({ report, handleCard }) => {
    const [viewRisks, setViewRisks] = useState(false);
    const [currRisk, setCurrRisk] = useState(0);

    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const isLaptop = useLaptop();
    const risks = [
        {
            title: 'Absestos',
            description: 'Common in homes in the early 1980s, inhaling or injesting its fibers can lead to serious respiratory diseases and cancers.'
        },
        {
            title: 'Lead Paint',
            description: 'Poses risks of lead poisoning, especially to children, potentially causing developmental delays, learning difficulties, and health issues '
        },
        {
            title: 'Knob & Tube Wiring',
            description: 'This early electrical wiring lacks grounding & is considered a fire hazard. Many insurance companies require an update.'
        },
        {
            title: 'Galvanized Plumbing',
            description: 'Prone to corrosion, reduced water pressure, and lead leaching; often needs replacement.'
        },
        {
            title: 'Cast Iron Sewer Pipe',
            description: 'These pipes can rust, causing leaks and blockages, and may need to be replaced with modern PVC.'
        },
        {
            title: 'Single Pane Windows',
            description: 'Provides less insulation against noise & temperature changes. Also is less energy efficient.'
        },
    ]

    const status_bar = <div className={styles.status_bar + " mt-4" /* + ((isMobile || isMobileMini) ? 'mt-4' : '')*/}>
        {Array.from({ length: risks.length }).map((_, index) => (
            <div
                key={index}
                className={styles.step}
                style={index == currRisk ? { background: 'black' } : {}}
                onClick={(event) => { viewRisk(event, index) }}
            ></div>
        ))}
    </div >

    const handleSwitch = (event) => {
        event.stopPropagation();
        setViewRisks(!viewRisks);
    }

    const viewRisk = (event, index) => {
        event.stopPropagation();
        if (isDesktop || isDesktopMini || isLaptop) {
            setCurrRisk(index);
        }
    }

    return (
        <Card className={`w-100 align-items-start text-start`}
            type={CardType.smallBox}
            linkPosition={CardLinkPosition.topRight}
            onClick={() => {
                handleCard('year')
            }}
        >
            {
                viewRisks ?
                    <>
                        <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Property Age</Card.Header>
                        <Card.Body>
                            <h4 className='fw-bold'>{risks[currRisk]?.title}</h4>
                            <p className='mt-2' style={{ fontSize: '14px', height: '100px', maxHeight: '100px', overflowY: 'auto' }}>{
                                risks[currRisk]?.description}
                            </p>
                            {status_bar}
                        </Card.Body>
                    </>
                    :
                    <>
                        <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Property Age</Card.Header>
                        <Card.Body>
                            <p style={{ fontSize: '27px' }}>Built in <b>{report?.details?.yearBuilt}</b>, we found <b>7 risks</b> and <b> 2 questions</b></p>
                            <Row className='mt-4' style={{ alignItems: 'flex-end' }}>
                                <Button onClick={(event) => { handleSwitch(event) }}>View Risks</Button>
                                <Img src={oldHouse} style={{ width: '7rem', height: '4rem' }} />
                            </Row>
                        </Card.Body>
                    </>
            }
        </Card>
    )
}

PropertyAge.propTypes = {
    report: PropTypes.object.isRequired,
    handleCard: PropTypes.func.isRequired
}

export const EnvRisk = ({ }) => {
    const isDesktop = useDesktop();
    const isDesktopMini = useDesktopMini();
    const isLaptop = useLaptop();

    const risks = [
        {
            title: 'Flood Risk',
            risk_score: 6,
            description: 'Flood risk is due to its location in a designated flood zone & its age, which may make it more vulnerable to water damage. Assess flood insurance options.'
        },
        {
            title: 'Heat Risk',
            risk_score: 6,
            description: 'This property experiences 20 days of 90°F heat annually, which can impact cooling costs. '
        },
        {
            title: 'Fire Risk',
            risk_score: 6,
            description: 'Property is in a heavily wooded area & has pine siding, this property has an elevated fire risk. Consider fire-resistant modifications and maintain clear defensible space.'
        },
        {
            title: 'Wind Risk',
            risk_score: 6,
            description: 'sadlf;kjasldkjf lsadkjf sladkjf sladkjfg sadfkngv;aklsdfnv laklasdfjg lasd fasldvmvkmsdkafjl!'
        },
        {
            title: 'Winter Weather Risk',
            risk_score: 6,
            description: 'sadlf;kjasldkjf lsadkjf sladkjf sladkjfg sadfkngv;aklsdfnv laklasdfjg lasd fasldvmvkmsdkafjl!'
        },
    ]

    const [currRisk, setCurrRisk] = useState(0);
    const [viewRisks, setViewRisks] = useState(false);

    const viewRisk = (index) => {
        if (isDesktop || isDesktopMini || isLaptop) {
            setCurrRisk(index);
        }
    }

    const status_bar = <div className={styles.status_bar + " mt-4" /* + ((isMobile || isMobileMini) ? 'mt-4' : '')*/}>
        {Array.from({ length: risks.length }).map((_, index) => (
            <div
                key={index}
                className={styles.step}
                style={index == currRisk ? { background: 'black' } : {}}
                onClick={() => { viewRisk(index) }}
            ></div>
        ))}
    </div >

    return (
        <Card className={`w-100 align-items-start text-start`}
            type={CardType.smallBox}
            linkPosition={CardLinkPosition.topRight}
        // onClick={() => {
        //     handleCard('year')
        // }}
        >
            {
                viewRisks ?
                    <>
                        <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>{risks[currRisk]?.title}</Card.Header>
                        <Card.Body>
                            <Row className="justify-content-start">
                                <div className={'flex-shrink-0'} style={{ maxWidth: '3.75rem' }}>
                                    <CircularProgressbar
                                        value={risks[currRisk]?.risk_score}
                                        maxValue={10}
                                        text={`${risks[currRisk]?.risk_score}/10`}
                                    />
                                </div>
                                <h2 className='text-start ms-3'>Moderate</h2>
                            </Row>
                            <p className='mt-3' style={{ fontSize: '12px', height: '80px', maxHeight: '80px', overflowY: 'auto' }}>
                                {risks[currRisk]?.description}
                            </p>
                            {status_bar}
                        </Card.Body>
                    </>
                    :
                    <>
                        <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Environmental Risk</Card.Header>
                        <Card.Body>
                            <p style={{ fontSize: '27px' }}>There&apos;s a <b>high risk</b> of <b>flood</b> for this property</p>
                            <Row className='mt-4' style={{ alignItems: 'flex-end' }}>
                                <Button onClick={() => { setViewRisks(true) }}>View Risks</Button>
                                <Img src={risk} style={{ width: '9rem' }} />
                            </Row>
                        </Card.Body>
                    </>
            }
        </Card>
    )
}

export const Permits = ({ }) => {
    return (
        <Card className={`${styles.small_card} w-100 align-items-start text-start`}
            type={CardType.smallBox}
            linkPosition={CardLinkPosition.topRight}
        // onClick={() => {
        //     handleCard('year')
        // }}
        >
            <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Property Permits</Card.Header>
            <Card.Body>
                <div className={styles.small_card_body}>
                    <h1>{"test"}</h1>
                    <p></p>
                    <Img src={calendar} />
                </div>
            </Card.Body>
        </Card>
    )
}

export const VerifiedFeats = ({ }) => {
    return (
        <Card className={`${styles.small_card} w-100 align-items-start text-start`}
            type={CardType.smallBox}
            linkPosition={CardLinkPosition.topRight}
        // onClick={() => {
        //     handleCard('year')
        // }}
        >
            <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Verified Feature</Card.Header>
            <Card.Body>
                <div className={styles.small_card_body}>
                    <h1>{"test"}</h1>
                    <p></p>
                    <Img src={calendar} />
                </div>
            </Card.Body>
        </Card>
    )
}

export const EnergyEfficiency = ({ }) => {
    const [viewChart, setViewChart] = useState(false);

    return (
        <Card className={`${styles.small_card} w-100 align-items-start text-start`}
            type={CardType.smallBox}
            linkPosition={CardLinkPosition.topRight}
        // onClick={() => {
        //     handleCard('year')
        // }}
        >
            {
                viewChart ?
                    <>
                        <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Energy Efficiency</Card.Header>
                        <Card.Body>
                            <p style={{ fontSize: '28px' }}>There&apos;s a <b>high risk</b> of <b>flood</b> for this property</p>
                            <Row className='mt-4'>
                                <Button onClick={() => { setViewChart(false) }}>View Risks</Button>
                            </Row>
                        </Card.Body>
                    </>
                    :
                    <>
                        <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Energy Efficiency</Card.Header>
                        <Card.Body>
                            <Col>
                                <Row style={{ justifyContent: 'start', alignItems: 'flex-end' }}>
                                    <h1>$2700</h1>
                                    <p>/year</p>
                                </Row>
                                <div className="px-3 rounded-pill" style={{ backgroundColor: '#2B5256', color: 'white' }}>
                                    <p>Annual Electricity Cost</p>
                                </div>
                            </Col>
                            <Row className='mt-4' style={{ alignItems: 'flex-end' }}>
                                <Button onClick={() => { setViewChart(true) }}>View Chart</Button>
                                <Img src={house} style={{ width: '10rem' }} />
                            </Row>
                        </Card.Body>
                    </>
            }
        </Card>
    )
}