import { PlusCircle } from '@phosphor-icons/react';
import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from "react";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import styles from './index.module.scss';

import { ReportAPI } from '@/api/report';
import logo from '@/assets/headerLogo.svg';
import hourglass from "@/assets/icons/hourglass.svg";
import leftAngleBlackIcon from '@/assets/icons/leftAngleBlack.svg';
import rightAngleBlackIcon from '@/assets/icons/rightAngleBlack.svg';
import Card, { CardType, CardLinkPosition } from '@/components/Card'
import { ChatComponent } from '@/components/ChatComponent/chatComponent';
import { UploadModal } from '@/components/HouseCard/UploadModal';
import { Col, Row, Icon, Contents, Img, Button, Input } from '@/components/Layout';
import { selectUserData } from '@/store/user/selectors';
import { issue_category } from '@/views/PropertyInsights/Issues';
import issueStyles from '@/views/PropertyInsights/Issues.module.scss';

export const AgeCard = ({ info, index }) => {
    return (
        <Card
            key={index}
            className={`w-100 align-items-start text-start`}
            type={CardType.smallBox}
        // linkPosition={CardLinkPosition.topRight}
        >
            <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Property Age</Card.Header>
            <Card.Body>
                <h4 className='fw-bold'>{info?.category.charAt(0).toUpperCase() + info?.category.slice(1)}</h4>
                <p className='mt-4' style={{ fontSize: '14px', height: '120px', maxHeight: '120px', overflowY: 'auto' }}>{
                    info?.description}
                </p>
            </Card.Body>
        </Card>
    )
}

AgeCard.propTypes = {
    info: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
}

export const KeyQuestions = ({ info, index }) => {
    return (
        <Card
            key={index}
            className={`w-100 align-items-start text-start`}
            type={CardType.smallBox}
        // linkPosition={CardLinkPosition.topRight}
        >
            <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Key Questions</Card.Header>
            <Card.Body>
                <h4 className='fw-bold'>{info?.category.charAt(0).toUpperCase() + info?.category.slice(1)}</h4>
                <ul
                    className="mt-4"
                    style={{
                        fontSize: '14px',
                        height: '120px',
                        maxHeight: '120px',
                        overflowY: 'auto',
                        listStyleType: 'disc', /* Ensures bullet points */
                        paddingLeft: '20px'   /* Adds spacing for the bullets */
                    }}
                >
                    {info?.questions?.map((question, index) => (
                        <li key={index}>{question.question}</li>
                    ))}
                </ul>
            </Card.Body>
        </Card>
    )
}

KeyQuestions.propTypes = {
    info: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
}

export const PermitsAnalysis = ({ info, index }) => {
    return (
        <Card
            key={index}
            className={`w-100 align-items-start text-start`}
            type={CardType.smallBox}
        // linkPosition={CardLinkPosition.topRight}
        >
            <Card.Header icon={hourglass} style={{ fontSize: '18px' }}>Permit Analysis</Card.Header>
            <Card.Body>
                <h4 className='fw-bold'>{info?.permit_type}</h4>
                <p className='mt-2' style={{ fontSize: '14px' }}><b>Permit date</b>: {info?.permit_date}</p>
                <p className='mt-2' style={{ fontSize: '14px', height: '120px', maxHeight: '120px', overflowY: 'auto' }}>{
                    info?.importance}
                </p>
            </Card.Body>
        </Card>
    )
}

PermitsAnalysis.propTypes = {
    info: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
}

export const IssueCard = ({ issue, index, openChat = null }) => {
    const [hover, setHover] = useState({
        id: -1,
        isHovered: false
    });

    return (
        <Card key={index}
            className={'text-start observed-element'}
            type={CardType.smallBox}
            // linkPosition={openChat ? CardLinkPosition.topRight : null}
            action={openChat ? () => {
                openChat();
            } : null}
            onMouseEnter={openChat ? () => {
                setHover({
                    id: index,
                    isHovered: true
                })
            } : null}
            onMouseLeave={openChat ? () => {
                setHover({
                    id: -1,
                    isHovered: false
                })
            } : null}>
            <Card.Header className={`${styles.card_header} py-1 gap-2`}>
                <div className={styles.icon_wrapper}>
                    {issue_category[issue.category]?.icon}
                </div>
                <p style={{ fontSize: '18px' }}>
                    {((hover.isHovered && index === hover.id)) && issue.issue.length >= 13 ? issue.issue.slice(0, 12) + '...' : issue.issue}
                </p>
            </Card.Header>

            <Card.Body className={'pt-2 h-100'}>
                <div className={styles.price_container}>
                    <div className={`${issueStyles.price}`}>
                        ${issue.cost_estimate_low}-{issue.cost_estimate_high}
                    </div>
                </div>
                <div className={`${styles.card_body_description} mt-2 overflow-auto`}>
                    {issue.context}
                </div>
                <div className={`${styles.page_container} justify-content-between align-items-end`}>
                    {/*<div className={commonStyles.price + ' ' + priceColor}>{issue.source}</div>*/}
                    <div>{issue.source}</div>
                    <Img src={issue_category[issue.category]?.image} />
                </div>
            </Card.Body>
        </Card>
    )
}

IssueCard.propTypes = {
    issue: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    openChat: PropTypes.func.isRequired
}





export const InfoPayModule = ({ }) => {
    const [issues, setIssues] = useState(null);
    const [ageInfo, setAgeInfo] = useState(null);
    const [keyQuestions, setKeyQuestions] = useState(null);
    const [permitAnalysis, setPermitAnalysis] = useState(null);
    const [chat, setChat] = useState(false);
    const [chatTopic, setChatTopic] = useState(null);
    const chatContainerRef = useRef(null);
    const [isWaitingForBotResponse, setIsWaitingForBotResponse] = useState(false);
    const [message, setMessage] = useState('');
    const initialBotMessage = "Hey there! I am the HomeScore Assistant! I'm here to help answer your questions "
    const [chatMessages, setChatMessages] = useState([]);
    const [chatTaskId, setChatTaskId] = useState("");
    const [chatId, setChatId] = useState(0);
    const user = useSelector(selectUserData);
    const [loading, setLoading] = useState('.');
    const [hasReport, setHasReport] = useState(false);
    const [report, setReport] = useState(null);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [gotIssues, setGotIssues] = useState(true);
    const [gotChecker, setGotChecker] = useState(true);
    const [token, setToken] = useState("");
    const [isValidToken, setIsValidToken] = useState(false);


    // useEffect(() => {
    //     const getChecker = async () => {
    //         if (token !== "") {
    //             try {
    //                 let response = await ReportAPI.getPropertyChecker(token);
    //                 if (response.data.data === null) {
    //                     setGotChecker(false);

    //                     await ReportAPI.initPropertyChecker(token);
    //                     response = await ReportAPI.getPropertyChecker(token);

    //                     setGotChecker(true);
    //                 }

    //                 const data = response.data.data;
    //                 setAgeInfo(data.age_risk);
    //                 setKeyQuestions(data.property_questions);
    //                 setPermitAnalysis(data.permits_analysis);

    //                 if (data.property_issues === null) {
    //                     setHasReport(false);
    //                 } else {
    //                     setHasReport(true);
    //                     setIssues(data.property_issues)
    //                 }
    //                 setIsValidToken(true)

    //             } catch (error) {
    //                 toast.error(error.response.data.detail)
    //             }
    //         }
    //     }

    //     getChecker();
    // }, [])

    const getChecker = async () => {
        if (token !== "") {
            try {
                let response = await ReportAPI.getPropertyChecker(token);
                if (response.data.data === null) {
                    setGotChecker(false);

                    await ReportAPI.initPropertyChecker(token);
                    response = await ReportAPI.getPropertyChecker(token);

                    setGotChecker(true);
                }

                const data = response.data.data;
                setAgeInfo(data.age_risk);
                setKeyQuestions(data.property_questions);
                setPermitAnalysis(data.permits_analysis);

                if (data.property_issues === null) {
                    setHasReport(false);
                } else {
                    setHasReport(true);
                    setIssues(data.property_issues)
                }
                setIsValidToken(true)
            } catch (error) {
                toast.error(error.response.data.detail)
            }
        }
    }

    const handleChange = (e, event) => {
        setToken(event.target.value);
    }

    const handleChat = async (issue, isChat, report_id) => {
        setChat(isChat);
        setChatTopic(issue);
        await ReportAPI.fetchOrCreateChat(report_id, issue?.issue).then(data => {
            if (data?.messages?.length > 0) {
                setChatId(data.id)
                setChatMessages(data.messages)
            } else {
                setChatId(data.id)
                setChatMessages([{
                    content: initialBotMessage + `about ${issue?.issue}. How can I help?`,
                    message_type: "AI",
                    issue: issue?.issue
                }]);
            }
        })
    }

    const handleMessage = (e, event) => {
        setMessage(event.target.value);
    }

    const handleMessageSend = async () => {
        if (message !== "" || message !== null || message !== undefined) {
            const newUserMessage = { content: message, message_type: "HUMAN", issue: chatTopic.issue, user: user.id };
            setChatMessages([...chatMessages, newUserMessage]);
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            await ReportAPI.sendMessage(message, chatId, chatTopic.issue).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
        }
    }

    async function handleKeyDown(event) {
        if (event.key === 'Enter') {
            const newUserMessage = { content: message, message_type: "HUMAN", user: user.id };
            setChatMessages([...chatMessages, newUserMessage]);
            await ReportAPI.sendMessage(message, chatId, chatTopic.issue).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
        }
    }

    useEffect(() => {
        let interval;
        if (isWaitingForBotResponse) {
            interval = setInterval(() => {
                setLoading((prevLoad) => {
                    // If there are already 3 dots, reset to one dot.
                    return prevLoad === '...' ? '.' : prevLoad + '.';
                });
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                ReportAPI.checkBotResponse(chatTaskId, chatId).then(data => {
                    if (data.state === "SUCCESS" || data.state === "FAIL") {
                        const newBotMessage = { content: data.result.content, message_type: "AI", issue: chatTopic.issue };
                        setChatMessages([...chatMessages, newBotMessage]);
                        setIsWaitingForBotResponse(false);
                        clearInterval(interval);
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isWaitingForBotResponse, chatTaskId, chatMessages]);


    useEffect(() => {
        const chatContainer = document.getElementById("chatmessage");
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, [chatMessages, chat]);

    // useEffect(() => {
    //     const getIssuesData = (report) => {
    //         ReportAPI.getReportIssues(reportId).then(data => {
    //             setGotIssues(false);
    //             if (report.has_report && data && data.length > 0) {
    //                 setIssues(data);

    //                 const risks = [
    //                     {
    //                         title: 'Absestos',
    //                         description: 'Common in homes in the early 1980s, inhaling or injesting its fibers can lead to serious respiratory diseases and cancers.'
    //                     },
    //                     {
    //                         title: 'Lead Paint',
    //                         description: 'Poses risks of lead poisoning, especially to children, potentially causing developmental delays, learning difficulties, and health issues '
    //                     },
    //                     {
    //                         title: 'Knob & Tube Wiring',
    //                         description: 'This early electrical wiring lacks grounding & is considered a fire hazard. Many insurance companies require an update.'
    //                     },
    //                     {
    //                         title: 'Galvanized Plumbing',
    //                         description: 'Prone to corrosion, reduced water pressure, and lead leaching; often needs replacement.'
    //                     },
    //                     {
    //                         title: 'Cast Iron Sewer Pipe',
    //                         description: 'These pipes can rust, causing leaks and blockages, and may need to be replaced with modern PVC.'
    //                     },
    //                     {
    //                         title: 'Single Pane Windows',
    //                         description: 'Provides less insulation against noise & temperature changes. Also is less energy efficient.'
    //                     },
    //                 ]

    //                 setAgeInfo(risks)
    //                 setGotIssues(true);
    //             } else if (report.has_report) {
    //                 setTimeout(getIssuesData(report), 5000);
    //             }
    //         }
    //         ).catch(error => {
    //             console.error("Error fetching issues data:", error);
    //         });
    //     }

    //     ReportAPI.getReport(reportId).then(data => {
    //         setReport(data);
    //         setHasReport(data?.has_report);
    //         getIssuesData(data);
    //     })
    // }, [])

    return (
        <Col
            className={styles.contents + ' container-fluid text-center w-100 mt-auto align-items-center pt-5'}
            style={{ height: "auto", minHeight: '43rem', backgroundColor: 'lightgrey' }}
        >
            <UploadModal report={report} isOpen={uploadOpen} onClose={() => { setUploadOpen(false) }} />
            <Contents>
                {/* {
                    chat ?
                        (
                            <>
                                <Row className='w-100 mb-5' style={{ justifyContent: 'space-around' }}>
                                    <div className={"px-2 py-1"} style={{ width: '40rem', backgroundColor: 'white', borderRadius: '40px' }}>
                                        <ChatComponent
                                            title={chatTopic?.issue}
                                            chat={chat}
                                            setChat={setChat}
                                            chatContainerRef={chatContainerRef}
                                            isWaitingForBotResponse={isWaitingForBotResponse}
                                            message={message}
                                            loading={loading}
                                            chatMessages={chatMessages}
                                            handleMessage={handleMessage}
                                            handleKeyDown={handleKeyDown}
                                            handleMessageSend={handleMessageSend}
                                        />
                                    </div>
                                    <IssueCard issue={chatTopic} index={0} />
                                </Row>
                            </>
                        )
                        :
                        ( */}
                <>
                    <Row style={{ marginBottom: '5rem' }}>
                        <Col>
                            <Img style={{ height: '3rem' }} src={logo} />
                            <p className="mt-2">Explore property risks and key questions. Upload photos or a home inspection for deeper insights.</p>
                            <p className='mt-1'><b>Note</b>: This module in development, there may be information not directly aligned with property you are searching</p>
                        </Col>
                        <Row className={'flex-nowrap gap-3'}>
                            {
                                (token && token !== "") && isValidToken ?
                                    (
                                        <>
                                            {/* {
                                                !hasReport && (
                                                    <Button type="button" style={{ width: '12rem' }} onClick={(event) => { setUploadOpen(true) }}>
                                                        <PlusCircle size={32} />
                                                        Home Inspection
                                                    </Button>
                                                )
                                            } */}
                                            <>
                                                <Icon src={leftAngleBlackIcon} size={36} className={'swiper-button-prev-unique cursor-pointer'} />
                                                <Icon src={rightAngleBlackIcon} size={36} className={'swiper-button-next-unique cursor-pointer'} />
                                            </>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Input className={styles.input} type="password" value={token} name="token" placeholder={"Authorization Token"} onChange={handleChange} />
                                            <Button onClick={() => { getChecker() }}>Submit</Button>
                                        </>
                                    )
                            }
                        </Row>
                    </Row>
                    {
                        gotChecker ?
                            (
                                <>
                                    {
                                        gotIssues ?
                                            (
                                                <>
                                                    <Swiper modules={[Navigation]}
                                                        navigation={{
                                                            nextEl: '.swiper-button-next-unique',
                                                            prevEl: '.swiper-button-prev-unique'
                                                        }}
                                                        autoHeight={true}
                                                        style={{ height: '21rem' }}
                                                        speed={600}
                                                        className={'w-100'}
                                                        breakpoints={{
                                                            [1300]: {
                                                                spaceBetween: 16,
                                                                slidesPerView: 4,
                                                                slidesPerGroup: 4,
                                                            },
                                                            [992]: {
                                                                spaceBetween: 16,
                                                                slidesPerView: 3,
                                                                slidesPerGroup: 3,
                                                            },
                                                            [768]: {
                                                                spaceBetween: 16,
                                                                slidesPerView: 2,
                                                                slidesPerGroup: 2,
                                                            },
                                                            [525]: {
                                                                spaceBetween: 10,
                                                                slidesPerView: 1,
                                                                slidesPerGroup: 1,
                                                            },
                                                        }}
                                                    >
                                                        {ageInfo?.map((risk, index) => (
                                                            <SwiperSlide key={index}>
                                                                <AgeCard info={risk} index={index} />
                                                            </SwiperSlide>
                                                        ))}
                                                        {keyQuestions?.map((questions, index) => (
                                                            <SwiperSlide key={index}>
                                                                <KeyQuestions info={questions} index={index} />
                                                            </SwiperSlide>
                                                        ))}
                                                        {permitAnalysis?.map((permit, index) => (
                                                            <SwiperSlide key={index}>
                                                                <PermitsAnalysis info={permit} index={index} />
                                                            </SwiperSlide>
                                                        ))}
                                                        {/* {issues?.map((issue, index) => (
                                                            <SwiperSlide key={index}>
                                                                <IssueCard issue={issue} index={index} openChat={() => { handleChat(issue, true, reportId) }} />
                                                            </SwiperSlide>
                                                        ))} */}
                                                    </Swiper>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <h1>Loading Cards...</h1>
                                                </>
                                            )
                                    }
                                </>
                            )
                            :
                            (
                                <h1>
                                    Loading HomeScore Property Cards
                                </h1>
                            )
                    }
                </>
                {/* )
                } */}
            </Contents>

        </Col>
    )
}