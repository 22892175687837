import { Menu, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import Gravatar from 'react-gravatar';
import { useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import styles from './index.module.scss';

import logo from '@/assets/headerLogo.svg';
import angleArrowDown from '@/assets/icons/angleArrowDown.svg';
import { Contents, Img, Icon, Row, Button } from '@/components/Layout';
import { dispatch } from "@/store";
import { getSubInfo } from "@/store/stripe/stripeSlice";
import { selectUserData } from '@/store/user/selectors';


const Header = ({ className = '' }) => {
    const user = useSelector(selectUserData);
    const navigate = useNavigate();
    const location = useLocation();

    let display_name = user.first_name ? (user.last_name ? (user.first_name + " " + user.last_name) : (user.first_name)) : user.username
    const currentTabClass = link => {
        return location.pathname.startsWith(link) ? ' ' + styles.current : '';
    };
    const currentLink = link => {
        return location.pathname.startsWith(link) ? location.pathname : link;
    };

    const [subscription, setSubscription] = useState(null);

    // useEffect(() => {
    //     StripeAPI.checkPayment().then(res => {
    //         setSubscription(res)
    //     })
    // }, [])

    const { remaining } = useSelector((state) => state.stripe);

    useEffect(() => {
        dispatch(getSubInfo());
    }, [dispatch]); // Dispatch only needs to be run once on component mount

    // Log `remaining` whenever it changes
    useEffect(() => {
        console.log('In Mobile Header Remaining:', remaining);
    }, [remaining]);

    const [isHovered, setIsHovered] = useState(true);

    const subscribe = () => {
        const preProcess = {
            remaining: remaining
        }
        localStorage.setItem('pre-process', JSON.stringify(preProcess))
        navigate('/subscriptions')
    }

    return (
        <header className={'d-flex flex-column justify-content-center align-items-center w-100 ' + className} style={{ zIndex: 100, minHeight: '80px' }}>
            <Contents className={'w-100 d-flex flex-column flex-lg-row gap-4 my-3 mx-0 justify-content-between align-items-center'}>
                <Img style={{ height: '1.75rem' }} src={logo} onClick={() => navigate('/')} />
                {remaining === 'Unlimited' ?
                    <div
                        className={`${styles.upgrade_btn}`}
                        style={{ background: 'black', color: 'white', borderRadius: '10px' }}
                    >
                        Unlimited Access
                    </div>
                    :
                    <Button
                        className={`${styles.upgrade_btn}`}
                        onClick={() => { subscribe() }}
                        onMouseEnter={() => setIsHovered(false)}
                        onMouseLeave={() => setIsHovered(true)}
                    >
                        {isHovered && location?.pathname === '/my-properties' && remaining > 0 ? `Add ${remaining} more home${remaining === 1 ? '' : 's'}` : 'Upgrade'}
                    </Button>
                }

                {user ? (
                    <Row className={styles.tabs}>
                        <Link
                            className={styles.tab + currentTabClass('/my-properties')}
                            to={currentLink('/my-properties')}
                        >
                            My Properties
                        </Link>
                        <Link
                            className={styles.tab + currentTabClass('/comparison')}
                            to={currentLink('/comparison')}
                        >
                            Comparison
                        </Link>
                        <Link
                            className={styles.tab + currentTabClass('/summary')}
                            to={currentLink('/summary')}
                        >
                            Personal Summary
                        </Link>
                    </Row>
                ) : null}

                {user ? (
                    <Row className={`${styles.profile} border rounded-pill w-100 flex-nowrap`}>
                        <Gravatar email={user.username} className={styles.profileImage} />
                        <div className={styles.profileData}>
                            <p className={styles.name}>{display_name}</p>
                            {/* <p className={styles.status}>BRONZE</p> */}
                        </div>
                        <Menu as="div" className={styles.menu}>
                            <Menu.Button className={styles.openButton}>
                                <Icon src={angleArrowDown} />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enterFrom={styles.closed}
                                enterTo={styles.open}
                                leaveTo={styles.closed}
                            >
                                <Menu.Items className={`${styles.items} d-flex flex-column gap-1 mt-4 bg-white p-3 w-100`}>
                                    <Menu.Item className={`${styles.item} d-flex justify-content-center align-items-center cursor-pointer py-3 px-4`}>
                                        <Link to="/profile">Profile</Link>
                                    </Menu.Item>
                                    <Menu.Item className={`${styles.item} d-flex justify-content-center align-items-center cursor-pointer py-3 px-4`}>
                                        <Link to="/password">Change Password</Link>
                                    </Menu.Item>
                                    {/*<Menu.Item className={`${styles.item} d-flex justify-content-center align-items-center cursor-pointer py-3 px-4`}>*/}
                                    {/*    <Link to="/subscriptions">Subscription</Link>*/}
                                    {/*</Menu.Item>*/}
                                    <Menu.Item className={`${styles.item} d-flex justify-content-center align-items-center cursor-pointer py-3 px-4`}>
                                        <Link to="/logout">Logout</Link>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </Row>
                ) : null}
            </Contents>
        </header>
    );
};

Header.propTypes = {
    className: PropTypes.string,
};

export default Header;
